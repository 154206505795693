import { css, keyframes } from 'styled-components';

export const flexCenterAligned = css`
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const flexColumn = css`
  display: flex;
  flex-direction: column;
`;

export const flexBetween = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const ellipsisText = css`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const openingEffect = (offset: number = -10) => css<{
  $isOpen: boolean;
}>`
  transition:
    opacity 0.3s ease,
    transform 0.3s ease,
    visibility 0.3s;

  ${({ $isOpen }) =>
    $isOpen
      ? css`
          visibility: visible;
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transform: translateY(${offset}px);
        `}
`;

const shimmer = keyframes`
  0% {
    background-position: -200px 0;
  }
  100% {
    background-position: 200px 0;
  }
`;

export const commonSkeletonStyles = css`
  background: linear-gradient(
    90deg,
    var(--border-secondary) 25%,
    var(--border-main) 50%,
    var(--border-secondary) 75%
  );
  background-size: 400px 100%;
  animation: ${shimmer} 1.5s infinite;
`;
